import $ from 'jquery';

$(document).ready(function () {

	$(window).scroll(function () {
		var sticky = $('header'),
			scroll = $(window).scrollTop();

		if (scroll >= 100) {
			sticky.addClass('fixed');
		} else {
			sticky.removeClass('fixed');

		}
	});

	//HAMBURGER

	$("#menu-toggle").click(function (e) {
		e.preventDefault();
		$("header").toggleClass("toggled");
		$("#sidebar-wrapper").toggleClass("toggled");
	});

	$("#sidebar-wrapper .close").click(function (e) {
		e.preventDefault();
		$("header").removeClass("toggled");
		$("#sidebar-wrapper").toggleClass("toggled");
	});

	$('.sidebar-nav .dropdown-menu > li > .dropdown-menu').parent().addClass('dropdown-submenu').find(' > .dropdown-item').addClass('dropdown-toggle');

	document.addEventListener('DOMContentLoaded', function () {
		// Sélectionner tous les éléments qui déclenchent l'ouverture des sous-menus
		const dropdownToggles = document.querySelectorAll('.dropdown-toggle');

		dropdownToggles.forEach(function (toggle) {
			toggle.addEventListener('click', function (event) {
				const dropdownMenu = this.nextElementSibling;
				// Fermer tous les menus ouverts sauf celui actuellement cliqué
				dropdownToggles.forEach(function (otherToggle) {
					if (otherToggle !== toggle) {
						const otherMenu = otherToggle.nextElementSibling;
						otherMenu.classList.remove('show');
						otherMenu.style.display = 'none';
					}
				});
				// Basculer le menu associé au bouton sur lequel on a cliqué
				dropdownMenu.classList.toggle('show');
				dropdownMenu.style.display = dropdownMenu.classList.contains('show') ? 'block' : 'none';
				event.stopPropagation();
			});
		});

		// Fermer tous les menus si un clic est détecté en dehors des menus
		document.addEventListener('click', function () {
			const dropdownMenus = document.querySelectorAll('.dropdown-menu');
			dropdownMenus.forEach(function (menu) {
				menu.classList.remove('show');
				menu.style.display = 'none';
			});
		});

		// Empêcher les événements de clic sur les menus de se propager au document
		const dropdownMenus = document.querySelectorAll('.dropdown-menu');
		dropdownMenus.forEach(function (menu) {
			menu.addEventListener('click', function (event) {
				event.stopPropagation();
			});
		});
	});


	/*
	$('.dropdown-menu > li > .dropdown-menu').parent().addClass('dropdown-submenu').find(' > .dropdown-item').addClass('dropdown-toggle');
	$('.dropdown-submenu > a').on("click", function(e) {
		var dropdown = $(this).parent().find(' > .show');
		$('.dropdown-submenu .dropdown-menu').not(dropdown).removeClass('show');
	$(this).next('.dropdown-menu').toggleClass('show');
		e.stopPropagation();
	});
	$('.dropdown').on("hidden.bs.dropdown", function() {
		$('.dropdown-menu.show').removeClass('show');
	});
	 */

	/*SUBMENU NOT BOOTSTRAP*/

	/*
	let timeoutID = null;

	$('#menu-navigation a').click((event) => {
		event.preventDefault();
		const navbar = $('.navbar');
		const menu = event.target.attributes.href.value.replace('#', '');

		if (timeoutID !== null)
			clearTimeout(timeoutID);

		let submenu = $('.submenu.submenu__' + menu).get(0);

		if (submenu && submenu.style && submenu.style.display === 'block') {
			navbar.removeClass('active');
			$(submenu).hide();
		} else {
			$('.submenu:not(.submenu__' + menu + ')').hide();
			navbar.addClass('active');
			$(submenu).show();
		}
	});

	$('#menu-navigation a').blur((event) => {
		timeoutID = setTimeout(() => {
			$('.submenu').hide();
			$('.navbar').removeClass('active');
			timeoutID = null;
		}, 1000);
	});
	*/

	/*ADD CLASS ACTIVE PARENT INPUT*/
	/*
	var fields = [ ".searchbox input" ];
	$(fields).each(function() {
		$(this).on('focus', function() {
			$(this).parent().parent('.gfield').addClass('active');
		});

		$(this).on('blur', function() {
			if ($(this).val().length == 0) {
				$(this).parent().parent('.gfield').removeClass('active');
			}
		});
		if ($(this).val() != '') $(this).parent('.css').addClass('active');

	});
	*/

	/*OPEN A FIXED SEARCH BOX*/

	$(".search__desktop i").click(function (e) {
		e.preventDefault();
		$(".searchbox").fadeIn(300);
	});

	$(".searchbox i").click(function (e) {
		e.preventDefault();
		$(".searchbox").fadeOut(300);
	});

});
document.addEventListener('DOMContentLoaded', function () {
	var closeButton = document.getElementById('closeButton');
	var topBar = document.getElementById('topBar');
	var mainContent = document.getElementById('mainContent');

	closeButton.addEventListener('click', function () {
		topBar.style.transition = 'height 0.3s linear, padding 0.3s linear';
		topBar.style.height = '0';
		topBar.style.padding = '0';
		mainContent.style.transition = 'padding-top 0.3s linear';
		mainContent.style.paddingTop = '0';
	});
});

// Ajouter un écouteur d'événements à tous les boutons 'btn-cities'
document.querySelectorAll('.btn-cities').forEach(function(btn) {
	btn.addEventListener('click', function() {
		// Afficher la popup
		document.querySelectorAll('.popup-cities').forEach(function(popup) {
			popup.style.display = 'block';
		});
	});
});

// Ajouter un écouteur d'événements au bouton de fermeture
document.querySelectorAll('.close-btn').forEach(function(btn) {
	btn.addEventListener('click', function() {
		// Cacher la popup
		document.querySelectorAll('.popup-cities').forEach(function(popup) {
			popup.style.display = 'none';
		});
	});
});


$(document).ready(function(){
	$('.toggle-accordion').click(function() {
		// Bascule la visibilité de la liste des villes
		$(this).next('.list-cities').slideToggle();
		// Bascule la classe pour l'animation de la flèche
		$(this).find('i').toggleClass('fa-chevron-down fa-chevron-up');
	});
});
