import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

	$('.cf_missions_presentation_slider').slick({
		arrows: true,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: '20%',
		prevArrow: $('.cf_missions_presentation_slider_prev'),
		nextArrow: $('.cf_missions_presentation_slider_next')
	});

	// Écoutez l'événement afterChange
	$('.cf_missions_presentation_slider').on('afterChange', function(event, slick, currentSlide) {
		// Vérifiez si l'appareil est mobile
		if (window.innerWidth <= 768) {
			// Obtenez l'URL de la bannière du slide actuel
			const currentSlideElement = slick.$slides.get(currentSlide);
			const bannerUrl = $(currentSlideElement).find('figure').data('banner-url');

			// Sélectionnez la section dont vous souhaitez changer le fond
			const section = document.getElementById('section-mission');

			// Changez le fond de la section
			section.style.backgroundImage = `url(${bannerUrl})`;

			// Ajoutez des styles supplémentaires si nécessaire
			section.style.backgroundRepeat = 'no-repeat';
			section.style.backgroundSize = 'cover';
			section.style.backgroundPosition = 'center center';
		}
	});

	if (typeof itemsNumberDesktop !== "undefined") { // Vérifiez si la variable existe
		$('.cf_gallery_slider').slick({
			arrows: true,
			infinite: true,
			slidesToShow: itemsNumberDesktop,
			slidesToScroll: 1,
			centerMode: true,
			centerPadding: '10%',
			prevArrow: $('.cf_gallery_slider_prev'),
			nextArrow: $('.cf_gallery_slider_next'),
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: itemsNumberDesktop,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	}

	$('.cf_gallery_slider_mission').slick({
		arrows: true,
		autoplay: true,
		autoplaySpeed: 1500,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: $('.cf_gallery_slider_mission_prev'),
		nextArrow: $('.cf_gallery_slider_mission_next'),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});
	
	$('.cf_blog_slider').slick({
		arrows: true,
		infinite: false,
		slidesToShow: 3.5,
		slidesToScroll:1,
		prevArrow: $('.cf_blog_slider_prev'),
		nextArrow: $('.cf_blog_slider_next'),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	});
	
	$('.cf_gallery_content_img_slider').slick({
		arrows: true,
		infinite: false,
		slidesToShow: 4,
		slidesToScroll:1,
		prevArrow: $('.cf_gallery_content_img_slider_prev'),
		nextArrow: $('.cf_gallery_content_img_slider_next'),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.cf_mp_slider').slick({
		arrows: true,
		autoplay: true,
		autoplaySpeed: 5000,
		infinite: true,
		slidesToShow: 4,
		slidesToScroll:1,
		prevArrow: $('.cf_mp_slider_prev'),
		nextArrow: $('.cf_mp_slider_next'),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.cf_parallax_slider').slick({
		arrows: true,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: $('.cf_parallax_slider_prev'),
		nextArrow: $('.cf_parallax_slider_next'),
		responsive: [ 
			{
				breakpoint: 1024,
				settings: {
					arrows: false,
					dots: true,
				}
			}
		]
	});
	
	/*
	$("div[class^=slider_column_]").each(function () {
		let id = $(this).attr('class').split("_").at(-1);
		$(".slider_column_" + id).slick({
			arrows: true,
			dots: false,
			infinite: true,
			speed: 300,
			slidesToShow: 4,
			slidesToScroll: 4,
			prevArrow: $('.slider_column_prev_' + id),
			nextArrow: $('.slider_column_next_' + id),
			responsive: [
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}
			]
		});
	});
	*/


});