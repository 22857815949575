import Parallax from 'parallax-js'

document.addEventListener("DOMContentLoaded", function() {
	var scene = document.getElementById('scene');
	if (scene) {
		var parallaxInstance = new Parallax(scene, {
			relativeInput: true,
			hoverOnly: true,
			scalarX: 10,
			scalarY: 10,
			invertX: true,
			invertY: true
		});

		scene.addEventListener('mouseenter', function() {
			parallaxInstance.enable();
		});

		scene.addEventListener('mouseleave', function() {
			parallaxInstance.disable();
		});
	}
});



